import $ from 'jquery';
import Isotope from 'isotope-layout';

export default class IsoTopeGrid {
  constructor($gridEl) {
    this.$grid = $($gridEl);
  }

  setup(options) {
    this.$grid.addClass('show');

    let el = document.createElement('div');
    let supports_grid = typeof el.style.grid === 'string';

    if (!supports_grid) {
      const maxHeight = Math.max.apply(null, this.$grid.find('.card').map(function () {
        return $(this).get(0).getBoundingClientRect().height;
      }).get());
      this.$grid.find('.card').css('height', maxHeight);
    }

    // let gutter = options && 'gutter' in options ? options.gutter : 15;

    // let gridSettings = {
    //   itemSelector: '.grid-item',
    //   stamp: '.stamp',
    //   layoutMode: 'fitRows',
    //   fitRows: {
    //     gutter: gutter,
    //   },
    //   masonry: {
    //     gutter: 15,
    //     fitWidth: true,
    //   },
    //   transitionDuration: 500,
    //   getSortData: {
    //     ordering: function(itemElem) {
    //       return parseInt(itemElem.getAttribute('data-ordering'), 10);
    //     },
    //   },
    // };

    // this.grid = new Isotope(this.$grid.get(0), gridSettings);

    // setTimeout(() => {
    //   this.$grid.addClass('show');
    // }, 200);
  }
}
