import './home.scss';

import * as dompack from "dompack";

import $ from 'jquery';

dompack.onDomReady(() => {
  $('.js-searchblock-item[data-image]').each(function() {
    // find the container upwards
    const $container = $(this).closest('.js-searchblock-container');

    // find the image element in the container
    const $image = $container.find('.js-searchblock-image');

    // update image src with data-image attribute on hover
    $(this).hover(() => {
      const imageUrl = $(this).data('image');
      $image.css('background-image', `url(${imageUrl})`);
    });
  });
});
