import "./mobilemenu.scss";
// import './jquery.mmenu.all.css';

import * as dompack from "dompack";

import $ from "jquery";
// require('./jquery.mmenu.all.min.js');

dompack.onDomReady(() => {
  // var $menu = $("#my-menu").mmenu({
  //   "extensions": [
  //     "right"
  //   ],
  //   "navbar": {
  //     title: " "
  //   }
  // });

  const $menu = new Mmenu("#my-menu", {
    navbar: {
      title: " ", //whintegration.config.site.sitetitle || " ",
    },
    slidingSubmenus: false,
    extensions: ["pagedim-black", "position-right"],
  });

  $("html").addClass("mm-right");

  const $icon = $("#toggle-mobile-menu");
  // let API = $menu.data("mmenu");

  $("#toggle-mobile-menu").click(function () {
    // API.open();
    $menu.API.open();
  });

  // if (API) {
  //   API.bind( "open:start", function() {
  //     setTimeout(function() {
  //       $icon.addClass( "is-active" );
  //     }, 100);
  //   });

  //   API.bind( "close:start", function() {
  //     setTimeout(function() {
  //       $icon.removeClass( "is-active" );
  //     }, 100);
  //   });
  // }
  if ($menu.API) {
    $menu.API.bind("open:start", function () {
      setTimeout(() => {
        $icon.addClass("is-active");
      }, 100);
    });

    $menu.API.bind("close:start", function () {
      setTimeout(() => {
        $icon.removeClass("is-active");
      }, 100);
    });
  }
});
