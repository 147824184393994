// WebHare
import '@mod-publisher/js/richcontent/all'; //support rich content (video, images)
import '@mod-system/js/wh/integration'; //activate WebHare features
import { openLinksInNewWindow } from '@mod-publisher/js/linkhandler';
import * as dompack from 'dompack';
import IsoTopeGrid from './shared/js/isotope-grid';
// import MSOCookieWall from '@mod-mso/components/cookiewall/';

import './shared/forms/';

// vendor
import '@fortawesome/fontawesome-free-webfonts/css/fa-solid.css';
import '@fortawesome/fontawesome-free-webfonts/css/fa-brands.css';
import '@fortawesome/fontawesome-free-webfonts/css/fa-regular.css';
import '@fortawesome/fontawesome-free-webfonts/css/fontawesome.css';

import './elfsteden.scss';

// pages
import './pages/home/home';
import './pages/content/content';
import './pages/countdown/countdown';
import './pages/search/search';

// embeddedobjects
import './embeddedobjects/embeddedobjects'

import "./components/autosuggest";

// vendor
import $ from 'jquery';

let MobileDetect = require('mobile-detect');
let md = new MobileDetect(window.navigator.userAgent);

// mobile menu
import './mobilemenu/mobilemenu';

openLinksInNewWindow();

dompack.onDomReady(() => {
  if (dompack.qS('#page404')) {
    $('html').addClass('page404');
  }

  // const cookieWall = new MSOCookieWall;
  // cookieWall.addConsentOverlay();

  $('html').toggleClass('is-mobile', !!md.mobile());

  $('.card--haslink').each(function() {
    const link = $(this).data('blocklink');
    if (isExternalLink(link))
      $(this).attr('onclick', `window.open('${link}');`);
  });

  // "fix" header title and subtitle
  $('.headerimage__title p.normal').each(function() {
    const html = $(this).html();
    $(this).replaceWith(html);
  });

  setTimeout(() => {
    if ($('#home-grid').length) {
      let grid = new IsoTopeGrid($('#home-grid'));
      grid.setup();
    }

    let lastLowestPos = -1;
    $('.searchblock__pulldown--gotopage').change(function() {
      if (this.value) {
        location.href = this.value;
      }
    });
  }, 500);

  $("#button-do").click(function(){
    $('html').toggleClass('show-searchblock show-searchblock-do');
  });

  $("#button-experience").click(function(){
    $('html').toggleClass('show-searchblock show-searchblock-experience');
  });

  $("#overlay").click(function(evt) {
    evt.preventDefault();
    $('html').removeClass('show-searchblock show-searchblock-do show-searchblock-experience enable-header-search');
  });

  // setup header search box
  $("#header-search").click(function() {
    const isCurrentlyVisible = $('html').hasClass('enable-header-search');
    $('html').toggleClass('enable-header-search');
    if (isCurrentlyVisible) {
      $('#header-search-input').val('');
      $('.headersearchbox__heading').remove();
    } else {
      $('.js-headersearch-input').focus();
    }
  });

  // setup cards on mobile/touch devices: show overlay on click
  if (!window.matchMedia('(hover: hover)').matches) {
    $('.card').click(function(evt) {
      if (evt.target.tagName == 'A' || evt.target.tagName == 'SPAN' || evt.target.classList.contains('card--missing-provider')) {
        return; // let through, it's either a direct link or the button
      }

      if ($(this).hasClass('card--haslink')) {
        return;
      }

      evt.preventDefault();
      const showingOverlay = $(this).hasClass('showoverlay');
      $('.card').removeClass('showoverlay');
      $(this).toggleClass('showoverlay', !showingOverlay);
    });
  }
});

function isExternalLink(link = '') {
  let ourdomain = (new URL(location.href)).host.toLowerCase();
  let internalhosts = [];
  if(ourdomain.substr(0,4) == 'www.')
    internalhosts = [ ourdomain, ourdomain.substr(4) ];
  else
    internalhosts = [ ourdomain, 'www.' + ourdomain ];

  if(!['http','https'].includes(link.split(':')[0]))
    return false; //not a browser protocol, skip

  if(link.target) //never overwrite an explicit target
    return false;

  let destdomain = (new URL(link)).host.toLowerCase();
  if(!internalhosts.includes(destdomain))
    return true;

  return false;
}
