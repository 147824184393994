import './forms.scss';

import * as dompack from 'dompack';
import * as forms from '@mod-publisher/js/forms';
forms.setup({ validate: true });

import UploadField from '@mod-publisher/js/forms/fields/upload';
dompack.register(".wh-form__upload", node => new UploadField(node));

import ImgEditField from '@mod-publisher/js/forms/fields/imgedit';
dompack.register(".wh-form__imgedit", node => new ImgEditField(node));
