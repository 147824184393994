import './blocks/blocks.es';
import './deals/deals.es';
import './embeddedobjects.scss';
import './providers/providers.es';
import './steps/steps.es';
import './subscribefollow/subscribefollow.es';
import './tips/tips.es';

import * as dompack from 'dompack';

dompack.onDomReady(() => {
});
