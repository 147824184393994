import './countdown.scss';
import * as whintegration from "@mod-system/js/wh/integration";

import * as dompack from 'dompack';
import { DateTime } from "luxon";

let countdownTo, nextYearBox, daysBox, hoursBox, minutesBox, secondsBox, interval;

dompack.onDomReady(() => {
  if (!document.documentElement.classList.contains('page-countdown'))
    return;

  countdownTo =  DateTime.local(2020, 10, 28, 13, 0, 0).setZone("Europe/Amsterdam");

  // DOM elements
  nextYearBox = document.getElementById("countdown-to");
  daysBox = document.getElementById("days");
  hoursBox = document.getElementById("hours");
  minutesBox = document.getElementById("minutes");
  secondsBox = document.getElementById("seconds");

  // call function first to display content immediately
  displayTimer();
  //update current Time and Time difference data every seconds
  interval = setInterval(() => displayTimer(), 1000);

  window.addEventListener('load', (event) => {
    window.setTimeout(() => {
      document.documentElement.classList.add('init');
    }, 110);
  });
});

function displayTimer() {
  const now = DateTime.local().setZone("Europe/Amsterdam");

  if (now >= countdownTo) {
    clearInterval(interval);
    interval = null;
    daysBox.innerText = 0;
    hoursBox.innerText = 0;
    minutesBox.innerText = 0;
    secondsBox.innerText = 0;
    location.href = 'https://www.elfstedenonline.nl/';
    return;
  }

  const diffInMonths = countdownTo.diff(now, ['days', 'hours', 'minutes', 'seconds']).toObject();
  daysBox.innerText = diffInMonths.days;
  hoursBox.innerText = diffInMonths.hours;
  minutesBox.innerText = diffInMonths.minutes;
  secondsBox.innerText = diffInMonths.seconds.toFixed(0);
}
