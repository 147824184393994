import './providers.scss';

import * as dompack from 'dompack';
import IsoTopeGrid from '../../shared/js/isotope-grid';

import $ from 'jquery';

dompack.onDomReady(() => {
  $('.embeddedobject--providers').each(function() {
    // keep it here for now, we might need it

    // let grid = new IsoTopeGrid($(this));
    // grid.setup({ gutter: 0 });

    // // calc the total height of the grid
    // // let height = getGridTotalHeight($(this).find('.grid-item'));
    // // $(this).find('.grid--providers').css('height', height);

    // // get the highest item on the first row, and set the contact item to the same height
    // let heights = [];
    // $(this).find('.grid-item').each(function() {
    //   // if (parseInt($(this).css('top')) == 0) {
    //     heights.push($(this).height(true));
    //   // }
    // })

    // let maxHeight = Math.max(...heights);
    // $(this).find('.grid-item--contact').css('height', maxHeight);
  });
});

function getGridTotalHeight($gridItems) {
  let $lastItems = $gridItems.slice(Math.max($gridItems.length - 3, 1))
  let heights = [];
  $lastItems.each(function() {
    heights.push(parseInt($(this).css('top')) + $(this).height());
  });

  return Math.max(...heights);
}
